import { RideSearchModel } from './RideSearchModel';
import { RideSearchResponseModel } from './response/RideSearchResponse';
import mapRideIntentResponseToModel from '../ride-intent/MapRideIntentResponseToModel';

export const mapRideSearchResponseToModel = (ride: RideSearchResponseModel): RideSearchModel => ({
  info: ride.info,
  intent: mapRideIntentResponseToModel(ride.intent),
});

export default mapRideSearchResponseToModel;
