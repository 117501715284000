
import { defineComponent } from 'vue';
import i18n from '@/i18n';
import TemplateSearchRides from '@/components/templates/rides/TemplateSearchRides.vue';

export default defineComponent({
  name: 'SearchRides',
  components: { TemplateSearchRides },
  metaInfo: { title: ` - ${i18n.t('tabs.searchRides')}` },
});
