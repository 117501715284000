
import Vue, { PropType } from 'vue';
import VueSelect from 'vue-select';
import VueLottiePlayer from 'vue-lottie-player';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomChip from '@/components/atoms/AtomChip.vue';
import AtomBadge, { BadgeVariant } from '@/components/atoms/AtomBadge.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import GenericAddressModel from '@/models/geolocation/GenericAddressModel';
import { formatAddress } from '@/common/GeoFormatters';
import SourceEnum from '@/enums/geolocation/SourceEnum';
import DefaultPoiEnum from '@/enums/geolocation/DefaultPoiEnum';
import PoiTypesEnum from '@/enums/geolocation/PoiTypesEnum';
import { CoordinatesModel } from '@/models/common/CoordinatesModel';

export interface OptionCategory {
  value: SourceEnum;
  title: string;
  selected: boolean;
  isVisible: boolean;
}

export default Vue.extend({
  name: 'AtomDropdownAddressSearch',
  extends: VueSelect,
  components: {
    VueSelect,
    VueLottiePlayer,
    AtomSvgIcon,
    AtomChip,
    AtomText,
    AtomBadge,
  },
  props: {
    id: {
      type: String as PropType<string>,
      default: '',
    },
    value: {
      default: null,
    },
    options: {
      type: Array as PropType<GenericAddressModel[]>,
      default: () => [],
    },
    categories: {
      type: Array as PropType<OptionCategory[]>,
      default: () => [],
    },
    onGeoLocalization: {
      type: Function,
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'icons/icon-location-pin',
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    showParkingAvailability: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selected: null as GenericAddressModel|null,
    category: null as OptionCategory|null,
    SourceEnum,
    fetching: false,
    BadgeVariant,
  }),
  computed: {
    filteredOptions(): GenericAddressModel[] {
      if (this.fetching) return [];

      const { options, category } = this;
      const op = [...options].map((option) => ({ ...option, label: '' }));
      if (!category || category.value === SourceEnum.ALL) return op;
      if (category && category.value === SourceEnum.SITE) return op.filter((o) => o.poiType === PoiTypesEnum.SITE);
      if (category && category.value === SourceEnum.FAVORITES) return op.filter((o) => o.poiType === PoiTypesEnum.USER);
      return category.value === null ? op : op.filter((o) => o.source === category.value);
    },
  },
  methods: {
    formatAddress,
    handleLoading(status: boolean): void {
      this.fetching = status;
    },
    fetchOptions(searchString: string): void {
      this.$emit('search', searchString, this.handleLoading);
    },
    locationHover(coordinates: CoordinatesModel): void {
      this.$emit('locationHover', coordinates);
    },
    locationHoverEnd() {
      this.$emit('locationHoverEnd');
    },
    setCategory(category: OptionCategory): void {
      this.category = category;
    },
    isCompanySite(poiType: PoiTypesEnum | undefined): boolean {
      return !!poiType && [PoiTypesEnum.COMPANY, PoiTypesEnum.SITE].includes(poiType);
    },
    getOptionIcon({ source, poiType, name }: GenericAddressModel): string {
      if (source === SourceEnum.FAVORITES) {
        if (name === DefaultPoiEnum.HOME) return 'icons/icon-home.svg';
        if (name === DefaultPoiEnum.WORK) return 'icons/icon-suitcase.svg';
        if (this.isCompanySite(poiType)) {
          return 'icons/icon-company.svg';
        }
        return 'icons/icon-heart.svg';
      }
      if (source === SourceEnum.HISTORY) {
        return 'icons/icon-history.svg';
      }

      return 'icons/search';
    },
    getOptionClasses({ source, poiType }: GenericAddressModel) {
      return {
        'dropdown-option': true,
        favorite:
          source === SourceEnum.FAVORITES &&
          !(poiType && [PoiTypesEnum.COMPANY, PoiTypesEnum.SITE].includes(poiType)),
      };
    },
  },
  mounted() {
    this.category = this.categories.length ? this.categories.find((c) => c.selected) || this.categories[0] : null;
    this.selected = this.$props.value;
  },
  watch: {
    value(value) {
      this.selected = value;
    },
  },
});
