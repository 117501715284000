
import Vue from 'vue';

export default Vue.extend({
  name: 'AtomWell',
  props: {
    variant: {
      type: String,
      required: true,
    },
    align: {
      type: String,
      default: 'center',
    },
    className: {
      type: String,
      default: '',
    },
  },
});
