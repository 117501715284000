
import Vue, { PropType } from 'vue';
import moment from 'moment';
import ClickOutside from 'vue-click-outside';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomTimePicker from '@/components/atoms/AtomTimePicker.vue';
import AtomChip from '@/components/atoms/AtomChip.vue';

import WeekDaysEnum from '@/enums/date/WeekDaysEnum';
// eslint-disable-next-line import/no-cycle
import { getCalendarViewDays, isValidDate } from '@/common/dateCalculations';
import { formatToShortDate, formatTime } from '@/common/DateFormatter';

export const CHANGE_EVENT_NAME = 'date-change';
export const CHANGE_RANGE_EVENT_NAME = 'date-range-change';

export interface WeekDays {
  dayName: string;
  dayCode: WeekDaysEnum;
  isSelected?: boolean;
}

export default Vue.extend({
  name: 'AtomCalendarComplex',
  components: { AtomButton, AtomSvgIcon, AtomTimePicker, AtomChip, AtomText },
  props: {
    // used for the headline besides the year and to not bind the component itself to translations
    monthNames: {
      type: Array as PropType<string[]>,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    btnTitle: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    // same as with month literals, expected order is monday -> sunday
    weekDays: {
      type: Array as PropType<WeekDays[]>,
      required: true,
    },
    // date to be selected at the start
    initialDate: {
      type: moment as PropType<moment.Moment>,
      required: false,
    },
    // isoWeek means the week starts with Monday
    isoWeek: {
      type: Boolean,
      required: false,
    },
    useAmPm: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // the latest valid date (including the date stated), used for styling and being selectable
    minDate: {
      type: moment,
      required: false,
    },
    maxDate: {
      type: moment,
      required: false,
    },
    // UI controls
    monthNavigation: {
      type: Boolean,
      default: true,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    ButtonVariant,
    internalMonth: null as moment.Moment | null,
    mode: 'departure' as 'departure' | 'arrival',
  }),
  computed: {
    monthAndYear(): string {
      return `${this.monthNames[this.selectedMonth.month()]} ${this.selectedMonth.year()}`;
    },
    calendarView(): moment.Moment[][] {
      return getCalendarViewDays(moment(this.selectedMonth), this.isoWeek);
    },
    sortedDayLiterals(): WeekDays[] {
      if (this.isoWeek) {
        return this.weekDays;
      }
      return [this.weekDays[6]].concat(this.weekDays.slice(0, 6));
    },
    todaysDate: {
      get(): moment.Moment {
        return moment().startOf('day');
      },
    },
    selectedMonth: {
      get(): moment.Moment {
        return (this.internalMonth?.clone() || this.initialDate.clone());
      },
      set(date: moment.Moment) {
        this.internalMonth = moment(date);
      },
    },
  },
  methods: {
    formatTime,
    formatToShortDate,
    currentDateTime() {
      return moment().startOf('minute');
    },
    selectToday(): void {
      const today = moment().startOf('minute').clone();
      this.dateSelectHandler(this.applyTime(today, this.initialDate));
    },
    selectPreviousMonth(): void {
      const date = this.selectedMonth.clone();
      const targetValue = date.subtract(1, 'months');
      // check if we would navigate before the month of the minimum value
      this.selectedMonth = targetValue;
    },
    selectNextMonth(): void {
      // moments are mutable, so Vue wouldn't catch any changes if we didn't create a new object
      const date = this.selectedMonth.clone();
      this.selectedMonth = date.add(1, 'months');
    },
    calculateDateCellClasses(date: moment.Moment): object {
      return {
        unHighlightedDate: (
          !date.isSame(this.selectedMonth, 'month')
          || !isValidDate(date, 'day', this.todaysDate)
        ),
        inactive: !isValidDate(date, 'day', this.todaysDate) || this.disabled,
        selected: date.isSame(this.initialDate, 'day'),
      };
    },
    dateSelectHandler(date: moment.Moment): void {
      if (this.disabled) return;

      const isDateValid = isValidDate(date, 'day', this.minDate, this.maxDate);
      if (!isDateValid) return;

      this.$emit(CHANGE_EVENT_NAME, moment(date));
    },
    hideCalendar() {
      if (!this.isOpen) this.$emit('onToggle', this.isOpen);
    },
    handleDateChange(date: moment.Moment) {
      const dateTime = this.applyTime(date, this.initialDate);

      this.dateSelectHandler(dateTime);
    },
    handleTimeChange(time: moment.Moment) {
      const dateTime = this.applyTime(this.initialDate, time);

      this.dateSelectHandler(dateTime);
    },
    applyTime(date: moment.Moment, time: moment.Moment): moment.Moment {
      return date.clone().hours(time.hours()).minutes(time.minutes()).seconds(0);
    },
    handleQuickTimeSelect(hours: number|undefined) {
      const time = hours ? moment().startOf('day').add(hours, 'hour') : moment().startOf('minute');
      this.handleTimeChange(time);
    },
    isChipSelected(date: moment.Moment, hours: number, minutes: number): boolean {
      return date.hours() === hours && date.minutes() === minutes;
    },
  },
  mounted() {
    // prevent click outside event with popupItem.
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.popupItem = this.$el;
  },
  watch: {
    initialDate(newVal: moment.Moment): void {
      this.internalMonth = moment(newVal);
    },
  },
  directives: {
    ClickOutside,
  },
});
