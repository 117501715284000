import GenericAddressModel from '@/models/geolocation/GenericAddressModel';

const CACHED_ADDRESSES_KEY = 'CACHED_ADDRESSES';

export const getCachedAddresses = (): GenericAddressModel[] => {
  const cachedAddresses = window.localStorage.getItem(CACHED_ADDRESSES_KEY);

  return cachedAddresses ? JSON.parse(cachedAddresses) : [];
};

export const cacheAddress = (address: GenericAddressModel[]) => {
  const [from, to] = address;
  const addresses = [{ from, to }, ...getCachedAddresses()];
  const addressesToCache = JSON.stringify(addresses);

  window.localStorage.setItem(CACHED_ADDRESSES_KEY, addressesToCache);
};

export default getCachedAddresses;
