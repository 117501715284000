import { GEOLOCATION_SERVICE_URL, APIGEE_DOMAIN } from '@/common/config';
import { http } from '@/services/http/HTTPService';
import getGenericAddresses from '@/services/geolocation/genericAddressBuilder';
import { QuicksearchResponse, QuicksearchResponseModel } from '@/models/geolocation/quicksearch/response/QuicksearchResponseModel';
import mapQuicksearchResponseToModel from '@/models/geolocation/quicksearch/MapQuickSearchResponseToModel';

const API_URL = `${APIGEE_DOMAIN}/${GEOLOCATION_SERVICE_URL}/quicksearch`;

// TODO: Debounce this call. Call only if query.length > 3
export const quicksearch =
async (query: string, userId?: string): Promise<QuicksearchResponse> => {
  const options = {
    params: {
      userId,
      query,
    },
  };
  const { status, data } = await http.get(API_URL, options);
  const quicksearchResponse =
    (data.result || []).map((q: QuicksearchResponseModel) => mapQuicksearchResponseToModel(q));

  return {
    status,
    data: getGenericAddresses(quicksearchResponse),
  };
};

export default quicksearch;
