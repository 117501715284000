import QuicksearchModel from './QuicksearchModel';
import { QuicksearchResponseModel } from './response/QuicksearchResponseModel';

const mapQuicksearchResponseToModel = (poi: QuicksearchResponseModel): QuicksearchModel => ({
  source: poi.source,
  poiType: poi.poiType,
  name: poi.label,
  comment: poi.comment,
  address: poi.address,
  position: {
    lat: poi.location.latitude,
    lng: poi.location.longitude,
  },
});

export default mapQuicksearchResponseToModel;
