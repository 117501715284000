import { http } from '@/services/http/HTTPService';
import { APIGEE_DOMAIN, RIDE_SEARCH_SERVICE_URL } from '@/common/config';
import { mapRideSearchResponseToModel } from '@/models/ride-search/mapRideSearchResponseToModel';
import { RideSearchResponse } from '@/models/ride-search/response/RideSearchResponse';

const BASE_URL = `${APIGEE_DOMAIN}/${RIDE_SEARCH_SERVICE_URL}`;

interface SearchRequestData {
  date: string;
  departureDate: boolean;
  from: string;
  to: string;
  role?: string;
}

export const searchRides = async (
  { date, departureDate, from, to, role }: SearchRequestData,
): Promise<RideSearchResponse> => {
  const options = {
    params: {
      role,
      date,
      departureDate,
      from,
      to,
      includeBasic: true,
    },
  };
  try {
    const { status, data } = await http.get(`${BASE_URL}/search`, options);

    return {
      status,
      data: (data?.result || []).map(mapRideSearchResponseToModel),
    };
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    return { status: error?.response?.status || 404, data: [] };
  }
};

export default searchRides;
